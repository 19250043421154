body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.demo-logo-vertical {
  height: 32px;
  margin: 16px;
  background: rgba(255,255,255,.2);
  border-radius: 6px
}

.sa-loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.sa-spinner-image {
  animation: spin 1s infinite linear;
  width: 200px;
  height: 200px;
}

/* 


@font-face {
  font-family: "Avenir-Next-LT-Pro";
  src: url(../src/views/assets/fonts/Avenir-Next-LT-Pro.rar);
}

@font-face {
  font-family: "Avenir-Next-LT-Pro-Regular";
  src: url(../src/views/assets/fonts/Avenir-Next-LT-Pro-Regular.otf);
}

@font-face {
  font-family: "Avenir-Next-LT-Pro-Bold";
  src: url(../src/views/assets/fonts/AvenirNextLTPro-Bold.otf);
}

@font-face {
  font-family: "Avenir-Next-LT-Pro-Demi";
  src: url(../src/views/assets/fonts/AvenirNextLTPro-Demi.otf);
}

@font-face {
  font-family: 'Avenir-Next-LT-Pro-Italic';
  src: url(../src/views/assets/fonts/Avenir-Next-LT-Pro-Italic.otf);
}

html {
  font-size: 10px;
}

#root {
  text-align: center;
}

.main {
  background-color: #f1f1f1;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  min-width: 1370px;
}

.ant-select-arrow {
  color: green;
}

.container {
  width: 100%;
  padding: 40px 80px;
  text-align: left;
}

.iconHover:hover {
  color: #1890ff;
}

.ant-layout-header {
  padding: 0;
  height: auto;
}

.ant-skeleton-element {
  width: 100%;
}

.popup-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  
}

/*

.popup-container::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px;
}


.ant-upload-list-picture-card-container {
  height: 80px;
  width: 80px
}

.ant-upload.ant-upload-select-picture-card {
  height: 80px;
  width: 80px
}

.ant-popover-inner {
  border-radius: 8px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-upload-list-item-thumbnail {
  font-size: 10px;
}

.row-vertical-divider>.col-vertical-divider:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}

.custom-scrollbar-div {
  padding: 8px;
}

.custom-scrollbar-div::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 8px;
}

.custom-scrollbar-div::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 4px;

}

.custom-scrollbar-div::-webkit-scrollbar-thumb {
  background-color: #a9ccec;
  border-radius: 4px;
}

.map-container {
  position: relative;
  width: 100%;

}

.map-edit-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.vertical-radio-group {
  display: flex;
  flex-direction: column;
}

.sa-spinner-image {
  animation: spin 1s infinite linear;
  width: 200px;
  height: 200px;
}

.custom-required {
  color: red;
}


.ant-select-dropdown-placement-bottomLeft {
  position: fixed !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image-viewer-icon{
  font-size: large;
}
.custom-scrollbar-div .ant-table-thead tr th, .custom-scrollbar-div .ant-table-tbody tr td {
  padding: 5px;
  font-size: 12px;
}

.report_filter .ant-card-body {
  padding: 10px 20px;
}

.filters_text {
  font-size: 14px;
  font-family: arial;
}

.filters_text span {
  font-weight: bold;
}


.reports_tbl.container {
  padding: 20px 70px 20px 10px;
}

.custom-scrollbar-div thead.ant-table-thead > tr > th {
  background: #7E7E7E !important;
  color: #fff;
}

.custom-scrollbar-div .ant-table-tbody > tr > td {
  border-bottom: 1px solid #cfcfcf;
  transition: background 0.3s;
  background: #DDDDDD;
}

.custom-scrollbar-div .ant-table-expanded-row-level-1 th.ant-table-cell {
  background: #a1a1a1 !important;
}

.custom-scrollbar-div .ant-table-expanded-row-level-1 td.ant-table-cell {
  background: #ffeaea;
}

.custom-scrollbar-div .ant-table-expanded-row-level-1 .ant-table-expanded-row-level-1 th.ant-table-cell {
  background: #d3d3d3 !important;
  margin: 0 !important;
  color: #000;
}

.custom-scrollbar-div .ant-table-expanded-row-level-1 .ant-table-expanded-row-level-1 td.ant-table-cell {
  background: #fff4f4;
}

.custom-scrollbar-div .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table
{ margin: -5px !important;}

td.ant-table-cell.ant-table-cell-ellipsis button.ant-btn.ant-btn-link, td.ant-table-cell.ant-table-cell-ellipsis button.ant-btn.ant-btn-link span {
  padding: 0;
  white-space: nowrap; // Prevent text from wrapping 
  overflow: hidden; // Hide overflowed text 
  text-overflow: ellipsis; // Show ellipsis for truncated text 
  max-width: 150px; /* Set a maximum width to control truncation 
}

.tottal_right, .ant-table-thead > tr > th.tottal_right {
  text-align: center;
}

.autoPad .ant-input-number-group-addon {
  padding: 0 5px;
}

.autoPad .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 5px;
}


.autoPad  .ant-input-number-input{
  padding: 0 5px;
}

.ant-btn-text {
  border-color: #e5e5e5;
}

.maxwidth-column-ant-table-column {
  max-width: 200px; // Adjust the value as needed 
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;  
  text-overflow: ellipsis;
}



.loadingInfo {
  text-align: center;
    font-weight: bold;
    position: absolute;
    height: 100%;
    width: 100%;
    background: #e5e5e5ba;
    top: 0;
    z-index: 999;
}

.custom-scrollbar-div .ant-table-tbody > tr >.last-column, .custom-scrollbar-div thead.ant-table-thead > tr > th.last-column {
  background: #818290 !important;
  color: #fff;
  text-align: right;
}

.custom-scrollbar-div .ant-table-tbody > tr.last-row > td {
  background: #818290;
  color: #fff;
}

.custom-scrollbar-div .ant-table-tbody > tr > td.first-column {
  border-right: 1px solid #cfcfcf;
}

.custom-scrollbar-div .ant-table-tbody > tr.ant-table-row.last-row:hover > td {
  color: #000;
}

.custom-scrollbar-div .ant-table-tbody > tr:hover > .last-column, .custom-scrollbar-div thead.ant-table-thead > tr:hover > th.last-column {
  color: #000;
} */